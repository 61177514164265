<template>
  <div class="login">
    <v-card>
      <v-card-title class="pb-0">
        <h1>Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field 
            label="Username" 
            prepend-icon="mdi-account-circle"
            v-model="data.email"
            type="email"
            :rules="emailRules"
          />
          <v-text-field 
            :type="showPassword ? 'text' : 'password'" 
            v-model="data.password"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ?' mdi-eye': 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="rules"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="info" @click="submit()">Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
  name: 'App',
  data () {
    return {
      showPassword: false,
      data: {},
      emailRules: [ 
        v => !!v || 'Champs requis', 
        v => /.+@.+/.test(v) || 'E-mail doit être valide' 
      ],
      rules: [
        value => !!value || 'Champs requis',
      ],
    }
  },
  methods: {
    submit(){
      if(this.data.email && this.data.password){

        axios.post(process.env.VUE_APP_APIURL + '/login' , this.data,{ withCredentials: true }
        ).then(Response => {

          if(Response.status === 200){

            const data = Response.data
            if(data.admin){
              localStorage.setItem('token', data.accessToken)
              localStorage.setItem('firstname', data.firstname)
              localStorage.setItem('lastname', data.lastname)
              this.$router.push(localStorage.getItem('redirect') ? localStorage.getItem('redirect') : '/properties')
            }
          }
        })
        .catch(err => {
          console.log(err.response)
        })
      }
    },
  }
}
</script>

<style scoped>
  .login{
    max-width: 600px;
    margin: auto;
  }
</style>